<template>
  <div id="page-instances">

    <b-alert v-if="!isTHS" variant="danger" show>Endast THS administratörer kan se denna sida</b-alert>
    <b-card v-else>
      <v-tabs background-color="white">
        <v-tab>{{ $t('COMMON.INSTANCES') }}</v-tab>
        <v-tab>{{ $t('COMMON.SALES') }}</v-tab>
        <v-tab>{{ $t('COMMON.SETTINGS') }}</v-tab>

        <v-tab-item class="py-8">
          <b-row>
            <b-col>
              <a href="#" id="multiple-invoices-btn" class="btn btn-primary font-weight-bolder font-size-sm ml-4"
                style="float: right;" @click="createInstanceClicked"><i class="menu-icon flaticon2-plus"
                  style="font-size: 1.0em;"></i>Skapa instans</a>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <InstanceEditorModal 
                ref="editInstance"
                :instance_id="selected_instance_id"
                @onInstanceCreated="onInstanceCreated" />
            </b-col>
          </b-row>
          <b-row>
            <b-col>

              <InstancesTable ref="instancesTable" :instances="instances" @onSelectInstance="onSelectInstance"
                @onDeleteInstance="onDeleteInstance" />
            </b-col>
          </b-row>
        </v-tab-item>
        
        <v-tab-item class="py-8">
          <SalesPage />
        </v-tab-item>

        <v-tab-item class="py-8">
          <h5></h5>
        </v-tab-item>
      </v-tabs>
    </b-card>


  </div>
</template>

<style lang="scss" scoped>

</style>


<script>

import axios from 'axios';
import { mapGetters } from 'vuex';
import InstancesTable from '@/view/pages/ml/ths/instances/InstancesTable.vue';
import InstanceEditorModal from '@/view/pages/ml/ths/instances/InstanceEditorModal.vue';
import SalesPage from '@/view/pages/ml/ths/SalesPage.vue';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';


export default {
  name: 'InstancesPage',
  components: {
    InstancesTable,
    InstanceEditorModal,
    SalesPage
  },
  computed: {
    ...mapGetters(['isTHS']),
  },
  mixins: [toasts],
  mounted() {
    this.getInstances();
  },
  data() {
    return {
      editInstance: false,
      selected_instance_id: null,
      instances: []
    };
  },
  watch: {

  },
  methods: {
    createInstanceClicked() {
      this.editInstance = true;
      this.$nextTick(() => {
        this.selected_instance_id = null;
        this.$refs['editInstance'].show();
      });
    },

    onSelectInstance(id) {
      this.editInstance = true;
      this.$nextTick(() => {
        this.selected_instance_id = id;
        this.$refs['editInstance'].show();
      });
    },

    onInstanceCreated(instance) {
      this.instances.push(instance);
    },

    onDeleteInstance(id) {
      axios
        .delete(`/ths/instance/${id}`)
        .then(res => {
          if (res.status === 204) {
            this.instances = this.instances.filter(item => item.id !== id);
            this.toastr('success', this.$t('COMMON.OK'), 'Instans borttagen');
          }
        })
        .catch(err => {
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte ta bort instans');
        });
    },

    getInstances() {
      const loader = this.$loading.show();

      axios
        .get(`/ths/instance`)
        .then(res => {
          loader && loader.hide();

          this.instances = res.data;
        })
        .catch(err => {
          loader && loader.hide();
          console.error(err);
          this.toastr('danger', this.$t('COMMON.ERROR'), 'Kunde inte hämta instanser');
        });
    },

  }
};
</script>
